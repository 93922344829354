import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuthContext } from 'components/AuthContext';

type Props = {
  role: 'employee' | 'employer';
  to: string;
};

export function useUserRoleRedirect({ role, to }: Props): null {
  const { replace } = useRouter();
  const { user, loading } = useAuthContext();
  useEffect(() => {
    if (loading || !user) return;
    if (role !== user.role) replace(to);
  }, [loading, user]);

  return null;
}
