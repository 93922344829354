// Framework
import { GetStaticProps } from 'next';
import Script from 'next/script';

// Third parties
import { gql } from '@apollo/client';

// Components
import { NewLayoutEmployer, PageMeta } from 'components';
import {
  CraftImage,
  ContactProps,
  EmployerHero,
  PartnersSection,
  GetStartedSection,
  EmployerFeedback,
  PackagesSection,
  HireCta,
  WhatWeDo,
  TeamSection,
  IntegrationsSection,
  Contact,
} from 'components/selfService';

// Types
type SelfServiceProps = {
  meta: PageMeta;
  customers: Array<CraftImage>;
  contact: ContactProps;
};

// Utils
import CMSClient from 'utils/CMSClient';
import { useUserRoleRedirect } from 'utils/hooks/useUserRoleRedirect';

/*--------------------------------------------------------------------*/

/**
 * Page
 */

export default function SelfService({
  meta,
  customers,
  contact,
}: SelfServiceProps): JSX.Element {
  useUserRoleRedirect({ role: 'employer', to: '/' });

  return (
    <NewLayoutEmployer meta={meta}>
      <Script
        src="//js-na1.hs-scripts.com/20147764.js"
        id="hs-script-loader"
        async
      />
      <EmployerHero />
      <PartnersSection logos={customers} />
      <GetStartedSection />
      <WhatWeDo />
      <EmployerFeedback />
      <PackagesSection />
      <HireCta />
      <TeamSection />
      <IntegrationsSection />
      <Contact {...contact} />
    </NewLayoutEmployer>
  );
}

/*--------------------------------------------------------------------*/

/**
 * Fetch props
 */

export const getStaticProps: GetStaticProps = async (context: any) => {
  const token = context?.preview ? context.previewData?.token : undefined;
  const GET_CONTENT = await CMSClient(token).query({
    query: gql`
      query SelfService {
        entry(slug: "self-service") {
          ... on selfService_selfService_Entry {
            recommended: bundleId
            meta(enabledForSite: true) {
              ... on meta_metaContent_BlockType {
                metaTitle
                metaDescription
              }
            }
            customers: logoList(enabledForSite: true) {
              ... on logoList_logoContent_BlockType {
                logo {
                  alt: title
                  src: url
                  width
                  height
                }
              }
            }
            contact(enabledForSite: true) {
              ... on contact_contactdefault_BlockType {
                title: contactTitle
                description
                address
                phone
                email
              }
            }
          }
        }
        features: entries(section: "pricingExplanation") {
          ... on pricingExplanation_default_Entry {
            title
            bundleId
            featureList {
              ... on features_default_Entry {
                id
                title
                description
                image {
                  width
                  height
                  src: url
                  alt: title
                }
              }
            }
          }
        }
      }
    `,
  });

  const { meta, customers, contact } = GET_CONTENT.data.entry;

  const pageData: SelfServiceProps = {
    meta: meta[0],
    customers: customers.map((c: any) => c.logo[0]),
    contact: contact[0],
  };

  return {
    props: pageData,
    revalidate: 60,
  };
};
